// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/ui/Container";
const isBackgroundRed = true;
const SecondPage = (props: PageProps) => (
  <Layout>
    <SEO title="Page two" />
    
    <div className="mt2rm lg:p-[15px]  bg1">   
    
     <div className="grid lg:grid-cols-3  md:grid-cols-1 gap-10 place-items-center ">
  <div className="items-end ml20 pad1r"> 
  <span className="contitle">Contact Us</span><br/>
  <h1 className="context1 mt-4">We’re here
to help you
Level up</h1><br/>
  <p className="contpara">The proof is in the pudding. Book your slots for a tailored one-on-one product demo by our AI solution experts.</p>
  </div>
  <div className="col-span-2 md:mt-[-9rem]  lg:mt-[-1rem] items-start mr2p mt7">
  <div className="flex flex-wrap">
  <div className="w-full conbox  pad1r lg:w-1/2 md:w-1/2 :w-1/2 bg-white text-black hover:text-white   border-2 border-gray-300  hover:bg-red-700  p2rem">
    <p className="f3">Book A Demo</p>
    <p className="f5">one small step for you one giant leap for your organisation
    one small step for you one giant leap.
    
    </p>

    <button className="mt2 bg-black-500  btn text-white font-bold py-2 px-4 rounded">
  Click Here
</button>
  </div>
  <div className="w-full   pad1r conbox lg:w-1/2 md:w-1/2 bg-white text-black hover:text-white   border-2 border-gray-300  hover:bg-red-700  p2rem">
    <p className="f3">Partner With Us</p>
    <p className="f5">one small step for you one giant leap for your organisation
    one small step for you one giant leap.
    
    </p>

    <button className="mt2 bg-black-500  btn text-white font-bold py-2 px-4 rounded">
  Click Here
</button>
  </div>
  
  <div className="w-full   pad1r conbox lg:w-1/2  md:w-1/2 bg-white text-black hover:text-white   border-2 border-gray-300  hover:bg-red-700  p2rem">
    <p className="f3">Work With Us</p>
    <p className="f5">one small step for you one giant leap for your organisation
    one small step for you one giant leap.
    
    </p>

    <button className="mt2 bg-black-500  btn text-white font-bold py-2 px-4 rounded">
  Click Here
</button>
  </div>
  <div className="w-full   pad1r conbox lg:w-1/2 md:w-1/2 bg-white text-black hover:text-white   border-2 border-gray-300  hover:bg-red-700  p2rem">
    <p className="f3">Pitch Us</p>
    <p className="f5">one small step for you one giant leap for your organisation
    one small step for you one giant leap.
    
    </p>

    <button className="mt2 bg-black-500  btn text-white font-bold py-2 px-4 rounded">
  Click Here
</button>
  </div>
 
</div>

  </div>

  
</div><br/><br/>


</div>
<img className="w-full h-[25rem]" src="/images/map.png" alt="Sunset in the mountains"/>

  </Layout>
)

export default SecondPage
